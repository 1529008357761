
<template>
    <form autocomplete="off" class="">
        <is-loading v-if="isLoading" :box="true" />
        <div class="row g-lg-4 g-3">
            <div class="col-md-6 col-lg-4">
                <label for="local_government_id" class="form-label">Local Government Area</label>
                <select @change="lgaChange()" v-model="data.local_government_id" 
                    name="local_government_id" id="local_government_id" class="form-select" >
                    <option disabled value="" >--choose LGA--</option>
                    <option v-for="(lga, lgIndex) in lgaOptions" :key="lgIndex" 
                    :value="lga.id">{{lga.name}}</option>
                </select>
            </div>
            <div class="col-md-6 col-lg-4">
                <label for="registration_area_id" class="form-label">Ward</label>
                <select @change="wardChange()" v-model="data.registration_area_id" name="registration_area_id" 
                id="registration_area_id" class="form-select">
                    <option disabled value="" >--choose ward--</option>
                    <option v-for="(ward, wIndex) in wardOptions" :key="wIndex" 
                    :value="ward.id">{{ward.name}}</option>
                </select>
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="">
                <label>Polling Unit </label>
                <multiselect 
                    mode="single"
                    searchable
                    valueProp="id"
                    trackBy="name" label="name"
                    :options="unitOptions" 
                    v-model="data.polling_unit_id" placeholder="--select--"></multiselect>
                </div>
            </div>
            <div class="col-md-6 col-lg-4">
                <label for="gender" class="form-label">Gender</label>
                <select v-model="data.gender" name="gender" id="gender" class="form-select" >
                <option disabled value="" >--select gender--</option>
                <option v-for="(gender, gdIndex) in genderOptions" :key="gdIndex" 
                    :value="gender.value">{{gender.label}}</option>
                </select>
            </div>
            <div class="col-md-6 col-lg-4">
                <label class="form-label" for="phoneNumber">Phone Number</label>
                <input id="phoneNumber" type="text" class="form-control" v-model="data.mobile"
                 placeholder="Phone Number" name="mobile" />
            </div>
            <div class="col-md-6 col-lg-4">
                <label class="form-label" for="ninNumber">NIN number</label>
                <input id="ninNumber" type="text" class="form-control" v-model="data.nin"
                 placeholder="NIN number" name="nin" />
            </div>
            <div class="col-md-6 col-lg-4">
                <label class="form-label" for="accountNumber">Account Number</label>
                <input id="accountNumber" type="text" class="form-control" v-model="data.account_number"
                 placeholder="Account Number" name="account_number" />
            </div>
            <div class="col-md-6 col-lg-4">
                <label class="form-label" for="firstName">First Name</label>
                <input id="firstName" type="text" class="form-control" v-model="data.first_name"
                placeholder="First name" name="first_name" />
            </div>
            <div class="col-md-6 col-lg-4">
                <label class="form-label" for="LastName">Last Name</label>
                <input id="LastName" type="text" class="form-control" v-model="data.last_name"
                 placeholder="Last Name" name="last_name" />
            </div>
        </div>
        <div class="row mt-3 justify-content-end">
        <div class="col-12">
            <div class="mt-3 text-end">
            <b-button variant="primary" class="me-3"
                @click.prevent="getList()"
                type="button"> Get List </b-button>
    
            <b-button variant="dark" 
                @click.prevent="resetForm()"
                type="button"> Reset </b-button>
            </div>
        </div>
        </div>
    </form>
    </template>
        
    <script>
    import Multiselect from '@vueform/multiselect'
    import IsLoading from '@/components/IsLoading.vue'
    
    export default {
        components: {
            IsLoading,
            Multiselect,
        },
        data() {
        return {
            isLoading: true,
            data: {
                local_government_id: "",
                registration_area_id: "",
                polling_unit_id:"",
                nin: "",
                gender: "",
                mobile: "",
                account_number: "",
                first_name: "",
                last_name: "",
            },
            genderOptions: [
                { label: 'Male', value: 'Male' },
                { label: 'Female', value: 'Female' },
                { label: 'Others', value: 'Others' }
            ],
            wardOptions: [],
            unitOptions: [],
            mirrorData: null,
        }
        },
        computed:{
            lgaOptions(){
                return this.$store.state.localGovernments
            },
        },
        methods: {
            getList() {
                var payload = Object.fromEntries(
                    Object.entries(this.data).filter(([_, v]) => !!v )
                );
                this.$emit('startSearch', payload)
            },
            resetForm() {
                this.data = {...this.mirrorData}
                this.wardOptions = [];
                this.unitOptions = [];
                this.$emit('resetFilters')
            },
            lgaChange(){
                this.data.registration_area_id = this.data.polling_unit_id  = "";
                this.wardOptions = this.unitOptions = [];
                this.isLoading = true
                this.$http.get(`${this.absoluteUrl()}/api/registration-areas?local_government_id=${this.data.local_government_id}`)
                    .then((response) => {
                    this.isLoading = false
                    if(response.data.success){
                        this.wardOptions = response.data.data
                    }
                })
            },
            wardChange(){
                this.data.polling_unit_id = "";
                this.unitOptions = [];
                if(!this.data.local_government_id || !this.data.registration_area_id){
                    return
                }
                this.isLoading = true
                this.$http.get(`${this.absoluteUrl()}/api/polling-units?local_government_id=${this.data.local_government_id}&registration_area_id=${this.data.registration_area_id}`)
                    .then((response) => {
                    this.isLoading = false
                    if(response.data.success){
                        this.unitOptions = response.data.data
                    }
                })
            },
            getOptionData(){
                this.isLoading = true;
                this.$store.dispatch('localGovernments')
                .then(response => this.isLoading = false );
            },
        },
        mounted(){
            this.getOptionData()
            this.mirrorData = JSON.parse(JSON.stringify(this.data))
        },
    }
    </script>
    