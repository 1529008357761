<template>
    <form autocomplete="off" class="">
        <div class="row gy-3 justify-content-center">
            <div class="col-lg-6">
                <vue-dropzone id="importCSVUploadDrop" ref="importCSVUploadDrop"
                :use-custom-slot="true" :maxFiles="1" acceptedFiles=".csv"
                @vdropzone-file-added="parseCSV($event)">
                <div class="needsclick m-0">
                    <span class="bx bx-cloud-upload fs-1"></span>
                    <h6> Upload CSV file</h6>
                    <span class="text-muted fs-13">
                        (It must be a CSV file - Comma delimiter)
                    </span>
                </div>
                </vue-dropzone>

                <div v-if="lineErrors.length" class="mt-4">
                    <h6>Errors Encountered in CSV file</h6>
                    <ol >
                        <li class="small text-danger" v-for="(lineError, leIndex) in lineErrors" :key="leIndex">{{ lineError }}</li>
                    </ol>
                </div>
            </div> 
            <div class="col-12">
                <div class="mb-3  mt-5 d-md-flex align-items-center justify-content-between">
                    <p class="mb-3 mb-md-0 order-md-2">Showing {{paginationInfo}} results</p>
                    <div>
                        <div class="d-flex">
                            <b-button class="me-3" variant="danger" @click.prevent="clearData()"
                            type="button"> Clear List </b-button>
                            <b-button  variant="primary" @click.prevent="upload()"
                            type="button"> Upload List </b-button>
                        </div>
                    </div>
                </div>
                <BTable
                    striped hover 
                    responsive="sm" align="middle"
                    :items="paginatedItems"
                    :fields="columns"
                    :current-page="currentPage"
                    >                    
                </BTable>
                <div class="mb-4">
                    <b-pagination v-model="currentPage" :limit="4" class="pagination-rounded"
                    :total-rows="pageData.length" :per-page="itemsPerPage"></b-pagination>
                </div>
            </div> 
        </div>
    </form>
</template>

<script>
import VueDropzone from "../VueDropzone.vue";

export default {
    components:{
        VueDropzone,
    },
    props:{
        program: {
            type: Object,
            required: true
        }
    },
    data(){
        return{
            current_page: 1,
            itemsPerPage: 10,
            headers: [ 'sn', 'full_name', 'mobile', 'nin', 'vin', 'local_government', 'account_number', 'bank_name'],
            requiredHeaders: [ 'full_name', 'mobile', 'nin', 'local_government', 'account_number', 'bank_name'],
            pageData:[],
            lineErrors:[]
        }
    },
    validations() {
        return {        
            data:{
                title: { required: required},           
            },

        }
    },
    computed:{
        columns(){
            return this.headers.map(item => {
                return { key: item, label: this.$filters.textFromSlug(item)}
            })
        },
        currentPage: {
            get() {
                return this.current_page
            },
            set(val) {
                if(!this.current_page || this.current_page == val) return
                this.current_page = val
            }
        },
        paginationInfo(){
            if(!this.pageData.length) return '0 - 0 of 0'
            return `${this.currentPage * this.itemsPerPage - (this.itemsPerPage - 1)} 
            - ${this.pageData.length - this.currentPage * this.itemsPerPage > 0 
            ? this.currentPage * this.itemsPerPage : this.pageData.length}
            of ${this.pageData.length}`
        },
        paginatedItems() {
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;
            return this.pageData.slice(startIndex, endIndex);
        }
    },
    methods:{
        upload() {
            if(!this.pageData.length){ this.alertError('Please import a CSV file before uploading'); return;}
            let formData = new FormData();
            formData.append('data', JSON.stringify({
                program_id: this.program.id,
                items: this.pageData
            }))
            this.$store.dispatch("changeLoaderValue", true)
            this.$http.post('/beneficiaries/upload', formData)
            .then((response) => {
                this.$store.dispatch("changeLoaderValue", false)
                if(response.data.success){
                    this.$emit('addItem')
                    this.resetForm();
                }
            })
        },
        clearData(){
            this.pageData  = [];
            this.lineErrors = []
        },
        resetForm(){
            this.$emit("closeMe")
        },
        parseCSV(file) {
            this.clearData();
            if(!file){ this.alertError('Please attach a CSV file'); return;}
            this.$filters.fileReaderAsText(file)
            .then((result) => {
                this.csvToJson(result);
            }).catch(error => console.log(error))
        },
        csvToJson(csv, delimiter = ',') {
            const lines = csv.split(/\r?\n/);
            const headerLength = this.headers.length;
            for (let i = 1; i < lines.length - 1; i++) {
                let lineData = lines[i].split(delimiter);
                if(lineData.length > headerLength){
                    lineData = this.parseCSVLine(lines[i], delimiter);
                }
                if (lineData.length === headerLength) {
                    const row = {};
                    this.headers.forEach((header, index) => {
                        row[header] = lineData[index].trim();
                    });
                    if (this.requiredHeaders.every(header => row[header])) {
                        this.pageData.push(row);
                    } else {
                        this.lineErrors.push(`#${i} is missing required data, skipping: ${lines[i]}`);
                    }
                }                
            }
        },
        parseCSVLine(line, delimiter) {
            const regex = new RegExp(
                `(${delimiter}|\\r?\\n|^)` + // Delimiters
                `(?:"([^"]*(?:""[^"]*)*)"|` + // Quoted fields
                `([^"${delimiter}\\r\\n]*))`, // Unquoted fields
                'g'
            );
            const result = [];
            let match;

            while ((match = regex.exec(line))) {
                if (match) {
                    const value = match[2] ? match[2].replace(/""/g, '"') : match[3];
                    if (typeof value === 'string') {
                        result.push(value);
                    } else {
                        console.error('Encountered error, skipping...');
                    }
                }
            }
            return result;
        },
    },
    mounted(){
        if(this.editMode){
            this.data = this.$filters.patchUpdatables(this.data, this.editItem)
        }
    }
}
</script>