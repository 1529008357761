
<template>
  <div>
    <div class="row gy-2">
      <div class="col-12">
        <h5 class="mb-3">List of Beneficiaries</h5>
        <div class="row mb-3 gy-2 justify-content-between">
          <div class="col-md-6 col-lg-4 d-flex">
            <div class="dropdown no-arrow me-2">
              <a class="btn btn-light bg-white dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                <i class="bx bx-menu"></i>
              </a>
              <ul class="dropdown-menu">
                <li @click.prevent="toggleFilters()">
                  <button class="dropdown-item" type="button">
                    Toggle Filters
                  </button>
                </li>
                <li @click.prevent="verifySelected()">
                  <button class="dropdown-item" type="button">
                    Verify Selected
                  </button>
                </li>
                <li @click.prevent="exportSelected()">
                  <button class="dropdown-item" type="button">
                    Export Excel
                  </button>
                </li>
                <li @click.prevent="popupModalShow = true">
                  <button class="dropdown-item" type="button">
                   Create a beneficiary
                  </button>
                </li>
              </ul>
            </div>
            <div class="input-group w-auto">
              <b-form-select style="max-width:100px;" class="form-select" v-model="itemsPerPage" :options="pageOptions"></b-form-select>
              <b-button variant="primary" class="" @click.prevent="popupModalImport = true" type="button">
                <i class="mdi mdi-file-upload-outline align-bottom"></i> <span class="d-inline"> Upload CSV</span></b-button>
            </div>
          </div>
          <div class="col-md-5">
            <div class="input-group mb-0">
              <input v-model="searchQuery" @input="updateSearchQuery" type="search" class="form-control" placeholder="Search here..." aria-label="Search here..." aria-describedby="button-search_TB">
              <button @click="fetchItems()" class="btn btn-primary" type="button" id="button-search_TB"><span class="ri ri-search-line"></span></button>
            </div>
          </div>
        </div>
        <div class="overflow-hidden">
          <common-portlet headertitle="Filters"
          :visible="enableFilters" :open="true">
            <div class="loading-viewport">
              <div class="card-body p-lg-3">
                <beneficiary-filter-form 
                @startSearch="setFilters($event)"
                @resetFilters="initializeAll()" />
              </div>
            </div>
          </common-portlet>
        </div>
        <div class="mb-3">
          <p class="mb-0">Showing {{paginationInfo}} results</p>
        </div>
        <div class="card">
          <div class="card-body p-3">
            <div class="table-responsive mb-0 loading-viewport">
              <is-loading v-if="isLoading" :box="true" />
              <b-table striped hover ref="selectableTable" :items="pageData.data"
                :fields="columns" responsive="sm"  align="middle">
                <template v-for="(column, colIndex) in columns.filter((i) => i.sortable)"
                  v-slot:[`head(${column.key})`] >
                    <div :key="colIndex" @click="setSortOrder(column)" class="cursor-pointer
                    d-flex align-items-center">
                      {{ column.label }} <span v-html="getSortingLabel(column.key)"></span>
                    </div>
                </template>
                <template v-slot:head(check)>
                  <div style="width:3.5rem;" class="form-check text-center">
                    <label class="form-check-label me-2" for="tableSRadioHead">
                      <input @change="selectAllRows($event)" type="checkbox"
                       class="form-check-input" id="tableSRadioHead" />
                    </label>
                  </div>
                </template>
                <template v-slot:cell(check)="data">
                  <div style="width:3.5rem;" class="form-check text-center me-auto">
                    <input v-model="selectedItems" type="checkbox" :value="data.item.id" class="form-check-input me-2"
                     :id="`tableSRadio${data.item.id}`" @click="handleCheckboxClick($event, data.item)" />
                    <label class="form-check-label" :for="`tableSRadio${data.item.id}`"></label>
                  </div>
                </template>
                <template v-slot:cell(first_name)="data">
                  <div style="min-width:7rem;" @click="navigateTo(data.item.id)" class="cursor-pointer">
                    {{ data.item.first_name }}
                  </div>
                </template>
                <template v-slot:cell(last_name)="data">
                  <div style="min-width:7rem;" @click="navigateTo(data.item.id)" class="cursor-pointer">
                    {{ data.item.last_name }}
                  </div>
                </template>
                <template v-slot:cell(full_name)="data">
                  <div style="min-width:7rem;" @click="navigateTo(data.item.id)" class="cursor-pointer">
                    {{ data.item.full_name }}
                  </div>
                </template>
                <template v-slot:cell(mobile)="data">
                  <div style="min-width:10rem;" @click="navigateTo(data.item.id)" class="cursor-pointer">
                    {{ data.item.mobile }}
                  </div>
                </template>
                <template v-slot:cell(nin_status)="data">
                  <div style="min-width:10rem;" @click="navigateTo(data.item.id)" class="cursor-pointer">
                    <span v-html="statusBadge(data.item.nin_status)"></span>
                  </div>
                </template>
                <template v-slot:cell(vin_status)="data">
                  <div style="min-width:10rem;" @click="navigateTo(data.item.id)" class="cursor-pointer">
                    <span v-html="statusBadge(data.item.vin_status)"></span>
                  </div>
                </template>
                <template v-slot:cell(bank_account_status)="data">
                  <div style="min-width:10rem;" @click="navigateTo(data.item.id)" class="cursor-pointer">
                    <span v-html="statusBadge(data.item.bank_account_status)"></span>
                  </div>
                </template>
                <template v-slot:cell(account_number)="data">
                  <div style="min-width:7rem;" @click="navigateTo(data.item.id)" class="cursor-pointer">
                    {{ data.item.account_number }}
                  </div>
                </template>
                <template v-slot:cell(account_name)="data">
                  <div style="min-width:10rem;" @click="navigateTo(data.item.id)" class="cursor-pointer">
                    {{ data.item.account_name }}
                  </div>
                </template>
                <template v-slot:cell(bank)="data">
                  <div style="min-width:7rem;" @click="navigateTo(data.item.id)" class="cursor-pointer">
                    <span v-if="data.item.bank" class="text-dark font-sm">{{ data.item.bank.name }}</span>
                  </div>
                </template> 
                <template v-slot:cell(local_government_id)="data">
                  <div style="min-width:7rem;" @click="navigateTo(data.item.id)" class="cursor-pointer">
                    <span v-if="data.item.lga" class="text-dark font-sm">{{ data.item.lga.name }}</span>
                  </div>
                </template> 
              </b-table>
            </div>
          </div>
        </div>
        <div class="mb-4">
          <b-pagination v-model="currentPage" :limit="4" class="pagination-rounded"
          :total-rows="pageData.total" :per-page="itemsPerPage"></b-pagination>
        </div>

        <b-modal size="lg" v-model="popupModalShow" hide-footer centered
          body-class="loading-viewport" title="Add beneficiary"
          title-class="fs-6" @hide="initializeAll()">
          <beneficiary-form v-if="popupModalShow" @closeMe="initializeAll()"
            @addItem="addItem($event)" :programId="item.program_id"/>
        </b-modal>

        <b-modal fullscreen v-model="popupModalImport" hide-footer centered
          body-class="loading-viewport" title="Import CSV file"
          title-class="fs-6" @hide="initializeAll()">
          <beneficiary-upload-form v-if="popupModalImport" @closeMe="initializeAll()"
            @addItem="fetchItems()" :program="program"/>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>


import _ from 'lodash';
import IsLoading from '@/components/IsLoading.vue';
import BeneficiaryFilterForm from '@/components/forms/BeneficiaryFilterForm.vue';
import CommonPortlet from '@/components/portlets/CommonPortlet.vue';
import BeneficiaryForm from "@/components/forms/BeneficiaryForm.vue"
import BeneficiaryUploadForm from '@/components/forms/BeneficiaryUploadForm.vue';

export default {
  name: "beneficiary-list",
  components:{
    IsLoading,
    BeneficiaryFilterForm,
    CommonPortlet,
    BeneficiaryForm,
    BeneficiaryUploadForm,
  },
  props:{
    program: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      popupModalShow: false,
      popupModalImport: false,
      enableFilters: false,
      queryFilters: null,
      searchQuery:"",
      pageOptions: [10, 50, 100, 500, 1000],
      columns: [
        {
          key: "check",
        },
        {
          key: "first_name",
          label: "First name",
          sortable: true
        },
        {
          key: "last_name",
          label: "Last name",
          sortable: true
        },
        {
          key: "full_name",
          label: "Full name"
        },
        {
          key: "mobile",
          label: "Mobile",
          sortable: true
        },
        {
          key: "nin_status",
          label: "NIN status",
          sortable: true
        },
        {
          key: "vin_status",
          label: "VIN status",
          sortable: true
        },
        {
          key: "bank_account_status",
          label: "Acoount status",
          sortable: true
        },
        {
          key: "account_number",
          label: "Account Number",
          sortable: true
        },
        {
          key: "account_name",
          label: "Account Name",
          sortable: true
        },
        {
          key: "bank",
          label: "Bank Name",
        },       
        {
          key: "local_government_id",
          label: "LGA",
          sortable: true
        }, 
      ],
      sortBy: {
        sort_column: "vin",
        sort_direction: "asc"
      },
      selectedItems:[],
      lastCheckedIndex: null,
      pageData:{
        data: []
      },
    }
  },
  computed: {
    currentPage: {
      get() {
        return this.pageData.current_page
      },
      set(val) {
        if(!this.pageData.current_page || this.pageData.current_page == val) return
        this.fetchItems(val)
      }
    },
    itemsPerPage: {
      get() {
        return +this.pageData.per_page || 50
      },
      set(val) {
        this.fetchItems(1, val)
      }
    },
    paginationInfo(){
      if(!this.pageData.total) return '0 - 0 of 0'
      return `${this.currentPage * this.itemsPerPage - (this.itemsPerPage - 1)} 
      - ${this.pageData.total - this.currentPage * this.itemsPerPage > 0 
      ? this.currentPage * this.itemsPerPage : this.pageData.total}
      of ${this.pageData.total}`
    },
    getSortingLabel(){
      return key => {
        let iconClass = 'text-muted ri-arrow-up-line';
        if(this.sortBy.sort_column != key) return `<span class='ms-2 ${iconClass}'></span>`;
        iconClass = this.sortBy.sort_direction == 'desc' ? 'ri-arrow-up-line' : 'ri-arrow-down-line';
        return `<span class='ms-2 ${iconClass}'></span>`
      }
    }
  },
  methods: {
    selectAllRows(e) {
      if(e.target.checked){
        this.selectedItems = this.pageData.data.map(i => i.id );
      }else{
        this.selectedItems = []
      }
    },
    navigateTo(id){
      this.$router.push({path: `/beneficiaries/${id}`});
    },
    updateSearchQuery: _.debounce(function(string) {
      this.fetchItems()
    }, 2000),
    fetchItems(page, per_page = null){
      let payload = {page: page || 1, per_page: per_page || this.itemsPerPage,
        ...this.sortBy, program_id: this.program.id
      }
      if(this.searchQuery){ payload.search = this.searchQuery }
      if(this.queryFilters) { Object.assign(payload, this.queryFilters)}
      this.isLoading = true
      this.$http.get(`/beneficiaries?${new URLSearchParams(payload).toString()}`)
        .then((response) => {
          this.isLoading = false
          if(response.data.success){
            this.pageData = response.data.data
            this.initializeAll();
          }
        })
    },
    toggleFilters(){
      this.enableFilters = !this.enableFilters; 
      this.initializeAll()
    },
    setFilters(data){
      this.queryFilters = data
      this.fetchItems()
    },
    setSortOrder(column) {
      if (this.sortBy.sort_column === column.key) {
        this.sortBy.sort_direction = this.sortBy.sort_direction === 'desc' ? 'asc' : 'desc';
      } else {
        this.sortBy.sort_column = column.key;
        this.sortBy.sort_direction = 'desc';
      }
      this.fetchItems();
    },
    verifySelected() {
      if(!this.selectedItems.length){
        this.alertError("Select the beneficiaries you'd like to verify."); return;
      }
      const payload = {
        url: '/beneficiaries/verify',
        data: {
          items: this.selectedItems.join(','),
          filename: 'beneficiaries_',
        }
      }
      this.$store.dispatch("changeLoaderValue", true)
      this.$http.get(`${payload.url}?${new URLSearchParams(payload.data).toString()}`)
        .then((response) => {
          this.$store.dispatch("changeLoaderValue", false)
          if(response.data.success){
            this.fetchItems(this.currentPage)
          }
        })
    },
    exportSelected() {
      if(!this.selectedItems.length){
        this.alertError("Select the beneficiaries you'd like to export."); return;
      }
      const payload = {
        url: '/beneficiaries/export-excel',
        data: {
          items: this.selectedItems.join(','),
          filename: 'beneficiaries_',
        }
      }
      this.$store.dispatch("changeLoaderValue", true)
      this.exelFileExport(payload)
        .then((response) => {
          this.$store.dispatch("changeLoaderValue", false)
          if(response.status != 200){
            this.alertError('Could not generate applications');
          }
      })
    },
    handleCheckboxClick(event, item) {
        const currentIndex = this.pageData.data.findIndex(i => i.id === item.id);
        if (this.lastCheckedIndex !== null && event.shiftKey) {
          let start = Math.min(this.lastCheckedIndex, currentIndex);
          let end = Math.max(this.lastCheckedIndex, currentIndex);

          for (let i = start; i <= end; i++) {
            const itemId = this.pageData.data[i].id;
            if (!this.selectedItems.includes(itemId)) {
              this.selectedItems.push(itemId);
            }
          }
        } else {
          this.lastCheckedIndex = currentIndex;
        }
    },
    initializeAll(){
      this.selectedItems = [];
      this.lastCheckedIndex = null
      this.popupModalShow = false;
      this.popupModalImport = false
      this.queryFilters = null;
    },
    addItem(item){
      this.pageData.data.unshift(item);
    }
  },
  mounted() {
    this.fetchItems()
  },
}
</script>

